import { WHATNOT_CHANNEL, EBAY_CHANNEL, ALLOWED_EBAY_TEMPLATE_FIELDS, ALLOWED_WHATNOT_TEMPLATE_FIELDS } from '@/constants'
import { handleConnections, handleWhatnotMarketplace, handleEbayMarketplace, handleEbayTemplates } from '../helpers/routes'

export default [
  {
    path: '/connections',
    name: 'Connections',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/Connections/Channels.vue'),
    beforeEnter: handleConnections,
    children: [
      { path: 'whatnot/connect', component: () => import('@/views/Connections/ConnectionProcess/Connect.vue'), props: { ...WHATNOT_CHANNEL }, meta: { channel: 'whatnot', templatesList: 'whatnotListingTemplates' } },
      { path: 'whatnot/connected', component: () => import('@/views/Connections/ConnectionProcess/Connect.vue'), props: { ...WHATNOT_CHANNEL }, meta: { channel: 'whatnot', templatesList: 'whatnotListingTemplates' } },
      { path: 'whatnot/listing-information', component: () => import('@/views/Connections/Whatnot/ConnectionProcess/ListingInformation.vue'), meta: { channel: 'whatnot', templatesList: 'whatnotListingTemplates' }, },
      { path: 'whatnot/policies', component: () => import('@/views/Connections/Whatnot/ConnectionProcess/Policy.vue'),  meta: { channel: 'whatnot', templatesList: 'whatnotListingTemplates' }, },

      {
        path: 'whatnot',
        component: () => import('@/views/Connections/Whatnot/index.vue'),
        children: [
          { path: '', component: () => import('@/views/Connections/Whatnot/Overview.vue') },
          { path: 'connection-settings', component: () => import('@/views/Connections/Whatnot/ConnectionSettings.vue') },
          {
            path: 'marketplace-templates',
            component: () => import('@/views/Connections/components/MarketplaceTemplate.vue'),
            // beforeEnter: handleWhatnotMarketplace,
            props: {
              title: 'Marketplace Templates' ,
              addButtonLabel: 'Add New Marketplace Template',
              listingTemplatesKey: 'whatnotListingTemplates',
              allowedFields: ALLOWED_WHATNOT_TEMPLATE_FIELDS
            }
          },
          {
            path: 'history',
            component: () => import('./RouterView.vue'),
            children: [
              { path: '', component: () => import('@/views/Connections/components/HistoryList/index.vue') },
              { path: ':id', component: () => import('@/views/Connections/Whatnot/HistoryList/HistoryInfo.vue') },
            ]
          },
        ]
      },

      { path: 'ebay/connect', component: () => import('@/views/Connections/ConnectionProcess/Connect.vue'), props: { ...EBAY_CHANNEL }, meta: { channel: 'ebay', templatesList: 'listingTemplates' } },
      { path: 'ebay/connected', component: () => import('@/views/Connections/ConnectionProcess/Connect.vue'), props: { ...EBAY_CHANNEL }, meta: { channel: 'ebay', templatesList: 'listingTemplates' } },
      { path: 'ebay/marketplace-listing-information', component: () => import('@/views/Connections/EbayDetails/ConnectionProcess/MarketplaceListingInformation.vue'),  meta: { channel: 'ebay', templatesList: 'marketplaceListingTemplates' }, },
      { path: 'ebay/policies', component: () => import('@/views/Connections/EbayDetails/ConnectionProcess/Policy.vue'),  meta: { channel: 'ebay', templatesList: 'marketplaceListingTemplates' }, },
      { path: 'ebay/export/shipping-settings', component: () => import('@/views/Connections/EbayDetails/ListingTemplate/ShippingSettings.vue') },
      { path: 'ebay/export/payment-return-settings', component: () => import('@/views/Connections/EbayDetails/ListingTemplate/PaymentReturnSettings.vue') },
      { path: 'ebay/export/listing-information', component: () => import('@/views/Connections/EbayDetails/ListingTemplate/ListingInformation.vue') },
      {
        path: 'ebay',
        component: () => import('@/views/Connections/EbayDetails/index.vue'),
        children: [
          { path: '', component: () => import('@/views/Connections/EbayDetails/Overview.vue') },
          {
            path: 'marketplace-templates',
            component: () => import('@/views/Connections/components/MarketplaceTemplate.vue'),
            beforeEnter: handleEbayMarketplace,
            props: {
              title: 'Marketplace Listing Templates' ,
              addButtonLabel: 'Add New Marketplace Listing Template',
              listingTemplatesKey: 'marketplaceListingTemplates',
              allowedFields: ALLOWED_EBAY_TEMPLATE_FIELDS
            }
          },
          {
            path: 'templates',
            component: () => import('@/views/Connections/components/MarketplaceTemplate.vue'),
            beforeEnter: handleEbayTemplates,
            props: {
              title: 'Listing Templates' ,
              addButtonLabel: 'Add New Listing Template',
              listingTemplatesKey: 'listingTemplates',
              allowedFields: ALLOWED_EBAY_TEMPLATE_FIELDS
            }
          },
          { path: 'connection-settings', component: () => import('@/views/Connections/EbayDetails/ConnectionSettings.vue') },
          {
            path: 'history',
            component: () => import('./RouterView.vue'),
            children: [
              { path: '', component: () => import('@/views/Connections/components/HistoryList/index.vue') },
              { path: ':id', component: () => import('@/views/Connections/EbayDetails/HistoryList/HistoryInfo.vue') },
            ]
          },
        ]
      },
      {
        path: 'collx',
        component: () => import('@/views/Connections/CollxDetails/index.vue'),
        children: [
          { path: '', component: () => import('@/views/Connections/CollxDetails/Overview.vue') },
          // { path: 'listing-information', component: () => import('@/views/Connections/CollxDetails/ListingInformation.vue') },
          { path: 'connection-settings', component: () => import('@/views/Connections/CollxDetails/ConnectionSettings.vue') },
          {
            path: 'export-history',
            component: () => import('./RouterView.vue'),
            children: [
              { path: '', component: () => import('@/views/Connections/components/HistoryList/index.vue') },
              { path: ':id', component: () => import('@/views/Connections/CollxDetails/ExportHistory/ExportInfo.vue') },
            ]
          },
        ]
      },
      {
        path: 'collx/connect',
        component: () => import('@/views/Connections/CollxDetails/Connection/index.vue'),
        children: [
          {
            path: 'account-connection',
            component: () => import('@/views/Connections/CollxDetails/Connection/AccountConnection.vue')
          },
          {
            path: 'listing-information',
            component: () => import('@/views/Connections/CollxDetails/Connection/ListingInformation.vue')
          },
        ]
      },
      {
        path: 'shopify',
        component: () => import('@/views/Connections/ShopifyDetails/index.vue'),
        children: [
          { path: '', component: () => import('@/views/Connections/ShopifyDetails/Overview.vue') },
          {
            path: 'export-history',
            component: () => import('./RouterView.vue'),
            children: [
              { path: '', component: () => import('@/views/Connections/components/HistoryList/index.vue') },
              { path: ':id', component: () => import('@/views/Connections/ShopifyDetails/ExportHistory/ExportInfo.vue') },
            ]
          },
          {
            path: 'export-settings',
            component: () => import('@/views/Connections/ShopifyDetails/ExportSettings.vue')
          },
        ]
      },
      // { path: ':channel', component: () => import('@/views/Connections/legacy/ChannelDetails.vue') },
    ]
  }
]
