import { useBatchStore } from '@/stores/BatchStore'
import { 
  REQUIRED_FIELDS_FOR_LIST_TO_COLLX, 
  REQUIRED_FIELDS_FOR_LIST_TO_WHATNOT,
  CONDITION, 
  TCG_CONDITION, 
  VIEW_GRADER_WEBSITE_LOOKUP_LINKS 
} from '@/constants'

export const getDefaultSelectedCardId = () => {
  const batchStore = useBatchStore()
  
  return batchStore.batchCardsBasedOnStatus.draftBatchCards.length ? batchStore.batchCardsBasedOnStatus.draftBatchCards[0].id : batchStore.batchCardsBasedOnStatus.readyToListBatchCards.length ? batchStore.batchCardsBasedOnStatus.readyToListBatchCards[0].id : null
}

export const getNextCardId = () => {
  const batchStore = useBatchStore()
  const currentCardIdIndex = Object.keys(batchStore.batchCardsBasedOnStatus.draftBatchCards).findIndex(item => batchStore.batchCardsBasedOnStatus.draftBatchCards[item].id === batchStore.selectedCardId)
  return batchStore.batchCardsBasedOnStatus.draftBatchCards.length >= currentCardIdIndex + 1 
    ? currentCardIdIndex === batchStore.batchCardsBasedOnStatus.draftBatchCards.length - 1 
      ? batchStore.batchCardsBasedOnStatus.draftBatchCards[0]?.id || batchStore.batchCardsBasedOnStatus.readyToListBatchCards[0]?.id
      : batchStore.batchCardsBasedOnStatus.draftBatchCards[currentCardIdIndex + 1].id 
    : batchStore.selectedCardId
}

export const omitQueryFilters = (fieldNames) => {
  const batchStore = useBatchStore()
  
  return Object.keys(batchStore.itemFilters).reduce((acc, item) => {
    if (!fieldNames.includes(item)) {
      acc[item] = batchStore.itemFilters[item]
    }

    return acc
  }, {})
}

export const toggleAllRows = ({ params }) => {
  const batchStore = useBatchStore()
  const haveUnselectedItems = !!batchStore.batchItems.find(element => element.select === false)

  if (haveUnselectedItems) {
    params.api.refreshCells({
      force: true,
      suppressFlash: true,
    })
    batchStore.batchItems = batchStore.batchItems.map((element, index) => {
      if (!element.select) {
        element.select = true
        if (batchStore.selectedRanges) {
          batchStore.selectedRanges.push({ 
            startIndex: index, 
            endIndex: index, 
            id: element.id,
            statusDisabled: element.status === 'draft' && element.has_errors,
            graded: element.graded 
          })

          batchStore.selectedCards[element.id] = element
        } else {
          batchStore.selectedRanges = [{ 
            startIndex: index, 
            endIndex: index, 
            id: element.id,
            statusDisabled: element.status === 'draft' && element.has_errors,
            graded: element.graded 
          }] 

          batchStore.selectedCards[element.id] = element    
        }
      }
      return element
    })
  } else {
    params.api.refreshCells({
      force: true,
      suppressFlash: true,
    })

    batchStore.batchItems.forEach(element => {
      if (element.select) {
        element.select = false
        batchStore.selectedRanges = null
        batchStore.selectedCards = {}
        batchStore.isBulkEditing = false
      }
    })
  }
}

export const handleOnRowClicked = (params, setFocus = false) => {
  const batchStore = useBatchStore()
  batchStore.pinnedCardData.isMatchOnly = false
  batchStore.selectedCardId = params.data.id
  const focusedRow = document.querySelectorAll('.ag-row-focus.ag-row-no-focus')

  if (params.data.select) {
    params.node.setSelected(true)
  }

  if (setFocus) {
    const selectedCell = document.querySelectorAll('.ag-cell-range-selected')

    if (selectedCell[0]) {
      selectedCell[0].classList.remove('ag-cell-range-selected')
      selectedCell[0].classList.remove('ag-cell-range-selected-1')
    }
    params.api.setFocusedCell(params.rowIndex, 'database_match')
  }

  if (focusedRow.length) {
    focusedRow[0].classList.remove('ag-row-focus')
    focusedRow[1] && focusedRow[1].classList.remove('ag-row-focus')
  }
}

export const sortSubsetSuggestions = (a, b) => {
  const subsetA = a.set.subset !== null ? a.set.subset : a.set.name
  const subsetB = b.set.subset !== null ? b.set.subset : b.set.name

  if (subsetA < subsetB) return -1
  if (subsetA > subsetB) return 1
  return 0
}

export const getCardNameForPricing = (card) => {
  let name = ''
  const { card_number, set, player, subset, variation, grader, grade_number, serial_number } = card

  if (set) {
    name = `${name} ${set}`
  } 

  if (card_number) {
    name = `${name} #${card_number}`
  }

  if (player) {
    name = `${name} ${player}`
  } 

  if (subset) {
    name = `${name} ${subset}`
  } 

  if (variation) {
    name = `${name} ${variation}`
  } 

  if (grader) {
    name = `${name} ${grader}`
  } 

  if (grade_number) {
    name = `${name} ${grade_number}`
  } 

  if (serial_number) {
    name = `${name} ${serial_number}`
  }

  return encodeURIComponent(name)
}

const addDigitsToSku = (skuNumber, skuWidth) => {
  let skuString = String(skuNumber)
  while (skuString.length < skuWidth) {
    skuString = '0' + skuString
  }

  return skuString
}

export const preparePreviewSku = (skuNumber, skuWidth, skuPrefix) => {
  const skuString = addDigitsToSku(skuNumber, skuWidth)

  return skuPrefix ? `${skuPrefix} ${skuString}` : skuString
}

export const getTitle = (card) => {
  const selectedCard = card

  if (selectedCard.title) {
    return selectedCard.title
  }

  if (selectedCard.template_title) {
    return selectedCard.template_title
  }

  return ''
}

export const getErrorsForListToWhatnot = (card) => {
  const { errors, suggested_edits: suggestedEdits, type } = card
  const errorsList = []
  
  REQUIRED_FIELDS_FOR_LIST_TO_WHATNOT.forEach((field) => {
    if (field === 'category') {
      if (type === 'sports') {
        errors.sport && errorsList.push(errors.sport)
        suggestedEdits.sport && errorsList.push(suggestedEdits.sport)
      } else {
        errors.game && errorsList.push(errors.game)
        suggestedEdits.game && errorsList.push(suggestedEdits.game)
      }
    } else {

      if (errors[field]) {
        errors[field] && errorsList.push(errors[field])
        suggestedEdits[field] && errorsList.push(suggestedEdits[field])
      }
    }
  })
}

export const getErrorsForListToCollX = (card) => {
  const { errors, suggested_edits: suggestedEdits, type } = card
  const errorsList = []

  REQUIRED_FIELDS_FOR_LIST_TO_COLLX.forEach((field) => {
    if (field === 'category') {
      if (type === 'sports') {
        errors.sport && errorsList.push(errors.sport)
        suggestedEdits.sport && errorsList.push(suggestedEdits.sport)
      } else {
        errors.game && errorsList.push(errors.game)
        suggestedEdits.game && errorsList.push(suggestedEdits.game)
      }
    } else {

      if (field !== 'start_price' || (errors[field] !== 'Sale Price: On eBay, the Buy It Now price must be greater than or equal to US $0.99')) {
        errors[field] && errorsList.push(errors[field])
        suggestedEdits[field] && errorsList.push(suggestedEdits[field])
      }
    }
  })

  const batchStore = useBatchStore()
  const graders = batchStore.config?.graders || []

  if (card.graded && !card.cert_no) {
    errorsList.push('To list on CollX, a certificate number is required for graded cards')
  }

  if (card.graded && card.grader) {
    // check if grader from CDP grader list
    const grader = graders.find((item) => item.name === card.grader)

    if (!grader) {
      errorsList.push('To list on CollX, please select a Grader from available options')
    }

    // check if grade number from CDP grade numbers list
    if (grader && card.grade_number) {
      const gradeNumber = grader.grading_scale.find((item) => item === card.grade_number)

      if (!gradeNumber) {
        errorsList.push('To list on CollX, please select a Grade from available options')
      }
    }

    if (grader && !card.grade_number) {
      errorsList.push('To list on CollX, please select a Grade from available options')
    }
  }

  if (card.graded && !card.grader) {
    errorsList.push('To list on CollX, please select a Grader from available options')
  }

  if (!card.graded && card.condition) {
    if (batchStore.selectedCardData.type === 'tcg' && !TCG_CONDITION.find(item => item.name === card.condition)) {
      errorsList.push('To list on CollX, please select Condition from available options')
    }

    if (batchStore.selectedCardData.type === 'sports' && !CONDITION.find(item => item.name === card.condition)) {
      errorsList.push('To list on CollX, please select Condition from available options')
    }
  }

  if (!card.graded && !card.condition) {
    errorsList.push('To list on CollX, please select Condition from available options')
  }

  return errorsList
}

export const preparedListOfCardsToExportOrPublish = ({ batchItems, selectedCards = {}, isBulkEditing, selectedRanges = [] }) => {
  let batchItems_ = [...batchItems]

  if (isBulkEditing) {
    if (Object.keys(selectedCards).length && !selectedRanges.length) {
      batchItems_ = Object.entries(selectedCards).map(([l, value]) => value)
    } else if (selectedRanges && selectedRanges.length) {
      batchItems_ = selectedRanges.reduce((acc, item) => {
        const cardData = batchItems_.find(card => card.id === item.id)

        if (cardData) acc.push(cardData)

        return acc
      }, [])
    }
  }

  const cardsIds = batchItems_.reduce((acc, item) => {
    acc.push(item.id)

    return acc
  }, [])

  return { cardsIds, cards: batchItems_ }
}

export const checkAreRequiredFieldsEmpty = (cards, connections) => {
  if (!connections.length) return

  let isCollxError = false
  let isEbayError = false
  let isWhatnotError = false

  for(let i = 0; i < cards.length; i++) {
    const errorsCollx = getErrorsForListToCollX(cards[i])
    const errorsWhatnot = getErrorsForListToWhatnot(cards[i])

    if (errorsWhatnot?.length) {
      isWhatnotError = true
    }

    if (errorsCollx.length) {
      isCollxError = true
    }

    if (cards[i].has_errors) {
      isEbayError = true
    }

    if (isCollxError && isEbayError) break
  }

  return {
    collx: isCollxError,
    ebay: isEbayError,
    whatnot: isEbayError
  }
  return isError
}

export const getViewGraderWebsiteLookupLink = (grader, certNo) => {
  return `${VIEW_GRADER_WEBSITE_LOOKUP_LINKS[grader]}${certNo}`
}