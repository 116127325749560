import { isValidEmail } from '@/helpers/general.js'

export const policiesValidation = (data = {}) => {
  const errors = []

  !data.ebay_fulfillment_policy_id && errors.push('fulfillmentPolicies')
  !data.ebay_location_id && errors.push('locations')
  !data.ebay_payment_policy_id && errors.push('paymentPolicies')
  !data.ebay_return_policy_id && errors.push('returnPolicies')

  return errors
}

export const connectionValidation = (data = {}) => {
  return [...listingTemplatesValidation(data), ...shippingValidation(data), ...paymentConnectionValidation(data)]
}

export const listingTemplatesValidation = (data = {}) => {
  const errors = []
  !data.name && errors.push('name')
  !data.ebay_category && errors.push('ebay_category')
  !data.listing_type && errors.push('listing_type')
  data.listing_type === 'auction' && !Number(data.start_price) && errors.push('start_price')
  data.listing_type === 'fixed' && !data.listing_price && !data.calculated_method && !data.percent_to_price && errors.push('percent_to_price')
  data.listing_type === 'fixed' && !data.listing_price && data.calculated_method && !data.fixed_amount_to_price && errors.push('fixed_amount_to_price')
  !data.duration && errors.push('duration')
  !data.make_live_immediately && !data.schedule_date && errors.push('schedule_date')
  !data.make_live_immediately && !data.schedule_time && errors.push('schedule_time')
  !data.set_for_all && !data.schedule_delay && errors.push('schedule_delay')
  !data.set_for_all && !data.schedule_delay && errors.push('schedule_delay')
  data.listing_type === 'auction' && data.enable_buy_it_now_price && !data.buy_it_now_price && errors.push('buy_it_now_price')
  data.listing_type === 'fixed' && data.auto_accept_offer && !data.auto_accept_offer_threshold && errors.push('auto_accept_offer_threshold')
  data.listing_type === 'fixed' && data.auto_decline_offer && !data.auto_decline_offer_threshold && errors.push('auto_decline_offer_threshold')
  data.listing_type === 'fixed' 
    && data.auto_accept_offer 
    && data.auto_decline_offer 
    && data.auto_accept_offer_threshold 
    && data.auto_decline_offer_threshold 
    && (data.auto_accept_offer_threshold <= data.auto_decline_offer_threshold)
    && errors.push('auto_decline_offer')
  return errors
}

export const shippingValidation = (data = {}) => {
  const errors = []

  !data.shipping_service && errors.push('shipping_service')
  !data.free_shipping && !Number(data.shipping_cost) && errors.push('shipping_cost')
  !data.free_shipping && data.additional_items === '' && errors.push('additional_items')
  data.handling_time === null && errors.push('handling_time')

  return errors
}

export const paymentConnectionValidation = (data = {}) => {
  const errors = []
  !data.city && errors.push('city')
  !data.state && errors.push('state')
  !data.zip_code && errors.push('zip_code')
  data.returns && !data.returns_within && errors.push('returns_within')
  data.returns && !data.refund_given_as && errors.push('refund_given_as')
  data.returns && !data.return_shipping_paid_by && errors.push('return_shipping_paid_by')
  // data.immediate_payment && !data.paypal_email && errors.push('paypal_email')
  // data.immediate_payment && data.paypal_email && !isValidEmail(data.paypal_email) && errors.push('paypal_email_invalid')

  return errors
}

export const collxListingTemplatesValidation = (data = {}) => {
  const errors = []

  data.listing_type === 'fixed' && !data.listing_price && !data.calculated_method && !data.percent_to_price && errors.push('percent_to_price')
  data.listing_type === 'fixed' && !data.listing_price && data.calculated_method && !data.fixed_amount_to_price && errors.push('fixed_amount_to_price')
  
  return errors
}

export const whatnotListingTemplatesValidation = (data = {}) => {
  const errors = []

  !data.name && errors.push('name')
  !data.whatnot_category_id && errors.push('whatnot_category_id')
  !data.listing_type && errors.push('listing_type')
  data.whatnot_listing_type === 'auction' && !Number(data.start_price) && errors.push('start_price')
  data.whatnot_listing_type === 'buy-it-now' && !data.listing_price && !data.calculated_method && !data.percent_to_price && errors.push('percent_to_price')
  data.whatnot_listing_type === 'buy-it-now' && !data.listing_price && data.calculated_method && !data.fixed_amount_to_price && errors.push('fixed_amount_to_price')

  return errors
}

export const whatnotShippingValidation = (data = {}) => {
  const errors = []

  !data.whatnot_shipping_weight_unit && errors.push('whatnot_shipping_weight_unit')
  !data.whatnot_shipping_weight && errors.push('whatnot_shipping_weight')

  return errors
}